import { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Contact } from "./contact";
import JsonData from "../data/data.json";


const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
  
      setLandingPageData(JsonData);
    }, []);
  
    return (
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Contact data={landingPageData.Contact} />
        </div>
    )
};

export default Home;


