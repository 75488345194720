import React from "react";
import SmoothScroll from "smooth-scroll";
import Home from "./components/home";
import Privacy from "./components/privacy";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <Router>
      <Switch>
        <Route path="/privacy" component={Privacy}/>
        <Route exact path="/" component={Home}/>        
        <Route path="/^(?!api).*/" component={Home}/>        
     </Switch>
    </Router>
  );
};

export default App;
