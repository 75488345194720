import { useState } from 'react'
import axios from 'axios';


const initialState = {
  name: '',
  email: '',
  message: '',
  url: '',
}

export const Contact = (props) => {
  const [{ name, email, message, url }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () =>{
    Array.from(document.querySelectorAll("input,textarea")).forEach(
      input => (input.value = "")
    );
    setState({ ...initialState })
  } 
  const handleSubmit = (e) => {
    e.preventDefault()

    if ( url  == null || url === "" ) {
      axios.post(`/api/`, { 
        name: name,
        email: email,
        message: message
      })
      .then(res => {
        clearState()
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <input type="text" id="url" name="url" onChange={handleChange} style={{ display:"none" }} tabIndex="-1" autoComplete="off" alt="if you fill this field out then your email will not be sent"></input>

                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Registered Office
                </span>
                {props.data ? props.data.company : 'loading'}
                <br/>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id='footer'>
        <div className='container text-center'>
            &copy; 2021 DevIT Solutions Limited 2021. All rights reserved.  Company registered in England &amp; Wales (No. 13345120).  Read our <a href = "privacy" target = "_blank" rel = "noopener noreferrer">privacy policy</a>.
        </div>
      </div>
    </div>

  )
}
